@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar-brand{
    background-image: url('/img/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
    height: 45px;
    margin-top: 4px;
}

// PRINT VIEW STUFF
.quote-print{
  h1, img{
    float: left;
  }
  h1{
    text-transform: uppercase;
    font-size: 25px;
    line-height: 100px;
    margin-top: 0;
    margin-bottom: 0;
  }
  img{
    height: 100px;
    width: auto;
  }
}
.main-row{
  margin-top: 30px;
}
.sidebar-column{
  h3{
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.main-column{

}
.options-row{
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
    padding: 8px 0;
    background-color: #f9f9f9;
    border-top: 2px solid #DDDDDD;
}
.quote-print-table{
    margin-bottom: 35px;
}

// VALIDATION STYLES
.ws-po-box{
    color: white;
    font-weight: bold;
    background-color: red;
}
.ws-po-arrowbox{
    border-bottom-color: red !important;
}

// Don't want this
@media print {
  a[href]:after {
    content: none;
  }
  .options-row{
      margin-top: 25px;
      margin-bottom: 25px;
  }
}
